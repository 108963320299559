// src/statsCollector.types.ts
var Quality = /* @__PURE__ */ ((Quality2) => {
  Quality2[Quality2["GOOD"] = 0] = "GOOD";
  Quality2[Quality2["OK"] = 1] = "OK";
  Quality2[Quality2["BAD"] = 2] = "BAD";
  Quality2[Quality2["TERRIBLE"] = 3] = "TERRIBLE";
  return Quality2;
})(Quality || {});

// src/statsCollector.ts
var STATS_SIZE = 60;
var createResolver = (statsReport) => {
  const idLookup = statsReport.reduce(
    (map, entry) => map.set(entry.id, entry),
    /* @__PURE__ */ new Map()
  );
  const expandTail = (stats, seenIds = []) => Object.keys(stats).reduce((expanded, key) => {
    const id = stats[key];
    expanded[key] = id;
    if (!id || typeof id !== "string" || seenIds.includes(id)) {
      return expanded;
    }
    switch (key) {
      case "codecId": {
        const codec = idLookup.get(id);
        if (codec) {
          expanded.codec = expandTail(codec, [...seenIds, id]);
        }
        break;
      }
      case "remoteCandidateId": {
        const remoteCandidate = idLookup.get(id);
        if (remoteCandidate) {
          expanded.remoteCandidate = expandTail(remoteCandidate, [
            ...seenIds,
            id
          ]);
        }
        break;
      }
      case "remoteId": {
        const remote = idLookup.get(id);
        if (remote) {
          expanded.remote = expandTail(remote, [...seenIds, id]);
        }
        break;
      }
      case "selectedCandidatePairId": {
        const selectedCandidatePair = idLookup.get(id);
        if (selectedCandidatePair) {
          expanded.selectedCandidatePair = expandTail(
            selectedCandidatePair,
            [...seenIds, id]
          );
        }
        break;
      }
      case "trackId": {
        const track = idLookup.get(id);
        if (track) {
          expanded.track = expandTail(track, [...seenIds, id]);
        }
        break;
      }
      case "trackIds": {
        if (Array.isArray(id)) {
          const ids = id;
          const tracks = ids.flatMap((id2) => {
            const track = idLookup.get(id2);
            return !track ? [] : [expandTail(track, [...seenIds, ...ids])];
          });
          expanded.tracks = tracks;
        }
        break;
      }
      case "transportId": {
        const transport = idLookup.get(id);
        if (transport) {
          expanded.transport = expandTail(transport, [
            ...seenIds,
            id
          ]);
        }
        break;
      }
      case "localCandidateId":
      case "localCertificateId":
      case "localId":
      case "mediaSourceId":
      case "remoteCertificateId":
      default:
        break;
    }
    return expanded;
  }, {});
  function expand(rawStats) {
    return expandTail(rawStats);
  }
  return {
    expand
  };
};
var inboundAudio = (statsData) => {
  const packetsReceived = statsData.packetsReceived ?? 0;
  const packetsLost = statsData.packetsLost ?? 0;
  const totalPackets = packetsReceived + packetsLost;
  return {
    type: statsData.type,
    kind: statsData.kind,
    jitter: statsData.jitter ?? 0,
    timestamp: statsData.timestamp,
    packetsTransmitted: packetsReceived,
    packetsLost,
    bytesTransmitted: statsData.bytesReceived,
    codec: statsData.codec?.mimeType,
    roundTripTime: statsData.transport?.selectedCandidatePair?.currentRoundTripTime,
    totalPercentageLost: totalPackets && packetsLost / totalPackets
  };
};
var outboundAudio = (statsData) => {
  const packetsSent = statsData.packetsSent ?? 0;
  const packetsLost = statsData.remote?.packetsLost ?? 0;
  const totalPackets = packetsSent + packetsLost;
  const roundTripTime = statsData.remote?.roundTripTime ?? statsData.transport?.selectedCandidatePair?.currentRoundTripTime;
  return {
    type: statsData.type,
    kind: statsData.kind,
    jitter: statsData?.remote?.jitter ?? 0,
    timestamp: statsData.timestamp,
    packetsTransmitted: packetsSent,
    packetsLost,
    bytesTransmitted: statsData.bytesSent,
    codec: statsData.codec?.mimeType,
    roundTripTime,
    totalPercentageLost: totalPackets && packetsLost / totalPackets
  };
};
var inboundVideo = (statsData) => {
  const packetsReceived = statsData.packetsReceived ?? 0;
  const packetsLost = statsData.packetsLost ?? 0;
  const totalPackets = packetsReceived + packetsLost;
  return {
    type: statsData.type,
    kind: statsData.kind,
    timestamp: statsData.timestamp,
    packetsTransmitted: packetsReceived,
    packetsLost,
    bytesTransmitted: statsData.bytesReceived,
    // firefox typically uses bitrateMean while chrome does not
    bitrate: statsData.bitrateMean,
    codec: statsData.codec?.mimeType,
    resolutionWidth: statsData.frameWidth,
    resolutionHeight: statsData.frameHeight,
    resolution: statsData.frameWidth && statsData.frameHeight ? `${statsData.frameWidth}x${statsData.frameHeight}` : void 0,
    // firefox typically uses framerateMean while chrome does not
    framesPerSecond: statsData.framerateMean ?? statsData.framesPerSecond,
    roundTripTime: statsData.transport?.selectedCandidatePair?.currentRoundTripTime,
    totalPercentageLost: totalPackets && packetsLost / totalPackets
  };
};
var outboundVideo = (statsData) => {
  const totalPacketSendDelay = statsData?.totalPacketSendDelay ?? 0;
  const packetsSent = statsData.packetsSent ?? 0;
  const packetsLost = statsData.remote?.packetsLost ?? 0;
  const totalPackets = packetsSent + packetsLost;
  const roundTripTime = statsData.remote?.roundTripTime ?? statsData.transport?.selectedCandidatePair?.currentRoundTripTime;
  return {
    type: statsData.type,
    kind: statsData.kind,
    timestamp: statsData.timestamp,
    packetsTransmitted: packetsSent,
    packetsLost,
    bytesTransmitted: statsData.bytesSent,
    totalPacketSendDelay: statsData.totalPacketSendDelay,
    averagePacketSendDelay: totalPacketSendDelay && totalPacketSendDelay / packetsSent,
    // firefox typically uses bitrateMean while chrome does not
    bitrate: statsData.bitrateMean,
    codec: statsData.codec?.mimeType,
    resolutionWidth: statsData?.frameWidth,
    resolutionHeight: statsData?.frameHeight,
    resolution: statsData.frameWidth && statsData.frameHeight ? `${statsData.frameWidth}x${statsData.frameHeight}` : void 0,
    // firefox typically uses framerateMean while chrome does not
    framesPerSecond: statsData.framerateMean ?? statsData.framesPerSecond,
    roundTripTime,
    totalPercentageLost: totalPackets && packetsLost / totalPackets
  };
};
var isInbound = (entry) => entry.type === "inbound-rtp";
var isOutbound = (entry) => entry.type === "outbound-rtp";
var isAudio = (entry) => entry.kind === "audio";
var isVideo = (entry) => entry.kind === "video";
var isAudioInbound = (entry) => isInbound(entry) && isAudio(entry);
var isAudioOutbound = (entry) => isOutbound(entry) && isAudio(entry);
var isVideoInbound = (entry) => isInbound(entry) && isVideo(entry);
var isVideoOutbound = (entry) => isOutbound(entry) && isVideo(entry);
var statsFrom = (statsReports) => {
  const resolver = createResolver(statsReports);
  const audioIn = statsReports.find(isAudioInbound);
  if (audioIn) {
    return inboundAudio(resolver.expand(audioIn));
  }
  const audioOut = statsReports.find(isAudioOutbound);
  if (audioOut) {
    return outboundAudio(resolver.expand(audioOut));
  }
  const videoIn = statsReports.find(isVideoInbound);
  if (videoIn) {
    return inboundVideo(resolver.expand(videoIn));
  }
  const videoOut = statsReports.find(isVideoOutbound);
  if (videoOut) {
    return outboundVideo(resolver.expand(videoOut));
  }
};
var statsFromRTCPeer = async (rtcPeer) => {
  const stats = await rtcPeer?.getStats(null);
  const reports = [];
  stats.forEach((report) => reports.push(report));
  return statsFrom(reports) ?? {
    type: "inbound-rtp",
    kind: "audio",
    packetsLost: 0,
    packetsTransmitted: 0
  };
};
var getRecentPacketsLost = (oldMetrics, newMetrics) => Math.max(newMetrics.packetsLost - oldMetrics.packetsLost, 0);
var getRecentTotalPackets = (oldMetrics, newMetrics) => {
  const totalPacketsDelta = newMetrics.packetsTransmitted + newMetrics.packetsLost - (oldMetrics.packetsTransmitted + oldMetrics.packetsLost);
  return Math.max(totalPacketsDelta, 1);
};
var getPacketStats = (oldMetrics, newMetrics) => [getRecentPacketsLost, getRecentTotalPackets].map(
  (fn) => fn(oldMetrics, newMetrics)
);
var removeObsolete = (metric, qualityHistorySize = STATS_SIZE) => {
  if (metric.length === qualityHistorySize) {
    metric.pop();
  }
};
var addPacketsStats = (metric, data) => {
  removeObsolete(metric);
  metric.unshift(data);
};
var recordCallPacketsStats = (oldStats, newStats, callPacketsStats) => {
  if (oldStats && newStats) {
    if (!callPacketsStats) {
      callPacketsStats = [];
    }
    addPacketsStats(callPacketsStats, getPacketStats(oldStats, newStats));
  }
  return callPacketsStats;
};
var addAudioQualityMetric = (metric, data) => {
  removeObsolete(metric);
  metric.unshift(data);
};
var addVideoQualityMetric = (metric, data) => {
  removeObsolete(metric);
  metric.unshift(data);
};
var recordCallQualityStats = (prevStats, stats, callQualityStats) => {
  const calcRecentPacketLoss = ({ pT = 0, prevPT = 0, pL = 0, prevPL = 0 }) => {
    if (pT <= 0) {
      return 0;
    }
    if (pT - prevPT <= 0) {
      return pL / pT;
    }
    return (pL - prevPL) / (pT - prevPT);
  };
  if (stats) {
    if (!callQualityStats) {
      callQualityStats = [];
    }
    if (stats.kind === "audio") {
      addAudioQualityMetric(
        callQualityStats || [],
        [
          calcRecentPacketLoss({
            pT: stats.packetsTransmitted,
            prevPT: prevStats?.packetsTransmitted,
            pL: stats.packetsLost,
            prevPL: prevStats?.packetsLost
          }),
          stats.jitter ?? 0
        ]
      );
    }
    if (stats.kind === "video") {
      addVideoQualityMetric(
        callQualityStats || [],
        calcRecentPacketLoss({
          pT: stats.packetsTransmitted,
          prevPT: prevStats?.packetsTransmitted,
          pL: stats.packetsLost,
          prevPL: prevStats?.packetsLost
        })
      );
    }
  }
  return callQualityStats;
};
var getQuality = (stats) => {
  const qualityOverTime = stats.map((stats2) => calculateQuality(stats2));
  const goodOrOk = qualityOverTime.filter(
    (stat) => stat === 0 /* GOOD */ || stat === 1 /* OK */
  );
  const qualitySum = qualityOverTime.reduce((acc, val) => acc + val, 0);
  return {
    quality: Math.round(qualitySum / qualityOverTime.length),
    goodOrOkQuality: goodOrOk.length / qualityOverTime.length,
    qualityOverTime
  };
};
var addDeltaStats = (newStats, cache) => {
  const oldStats = cache.previous ?? Object.keys(newStats).reduce((stats, key) => {
    const statKey = key;
    if (typeof newStats[statKey] === "number" && statKey !== "timestamp") {
      return { ...stats, [statKey]: 0 };
    }
    return { ...stats, [statKey]: newStats[statKey] };
  }, {});
  const deltaStats = {
    ...newStats
  };
  const callPacketsStats = recordCallPacketsStats(
    oldStats,
    newStats,
    cache.callPacketsStats
  );
  const metrics = [
    [newStats, oldStats, deltaStats, callPacketsStats ?? []]
  ];
  metrics.forEach(([newMetrics, oldMetrics, deltaMetrics, packets]) => {
    if (newMetrics?.bytesTransmitted && newMetrics?.timestamp && oldMetrics?.bytesTransmitted && oldMetrics?.timestamp && deltaMetrics) {
      const dMs = newMetrics.timestamp - oldMetrics.timestamp;
      const dBytes = newMetrics.bytesTransmitted - oldMetrics.bytesTransmitted;
      if (dMs !== 0) {
        deltaMetrics.bitrate = Math.round(dBytes * 8 / (dMs / 1e3));
      }
    }
    if (deltaMetrics) {
      const [recentPacketsLost, recentTotalPackets] = packets.reduce(
        (acc, [lost, total]) => {
          acc[0] += lost;
          acc[1] += total;
          return acc;
        },
        [0, 0]
      );
      deltaMetrics.recentPercentageLost = recentTotalPackets === 0 ? 0 : recentPacketsLost / recentTotalPackets;
    }
  });
  const callQualityStats = recordCallQualityStats(
    oldStats,
    deltaStats,
    cache.callQualityStats
  );
  return [
    deltaStats,
    getQuality(callQualityStats ?? []).quality,
    callQualityStats
  ];
};
var calculateQuality = (stats) => {
  let packetLoss;
  let jitter;
  if (typeof stats === "number") {
    packetLoss == stats;
  } else {
    [packetLoss, jitter] = stats;
  }
  let callQuality = 1 /* OK */;
  if (typeof packetLoss === "number") {
    if (packetLoss < 0.01) {
      callQuality = 0 /* GOOD */;
    } else if (packetLoss < 0.03) {
      callQuality = 1 /* OK */;
    } else if (packetLoss < 0.1) {
      callQuality = 2 /* BAD */;
    } else {
      callQuality = 3 /* TERRIBLE */;
    }
  }
  if (jitter && jitter > 0.04) {
    if (callQuality === 0 /* GOOD */) {
      callQuality = 1 /* OK */;
    } else if (callQuality === 1 /* OK */) {
      callQuality = 2 /* BAD */;
    } else if (callQuality === 2 /* BAD */) {
      callQuality = 3 /* TERRIBLE */;
    }
  }
  return callQuality;
};
var createStatsCollector = ({
  input,
  signals: { onCallQuality, onCallQualityStats, onRtcStats },
  interval = 1e3
}) => {
  const newCache = () => ({
    callQuality: 0 /* GOOD */,
    callPacketsStats: [],
    callQualityStats: []
  });
  let cache = newCache();
  const pushStats = () => {
    void statsFromRTCPeer(input).then((newStats) => {
      const [stats, callQuality, callQualityStats] = addDeltaStats(
        newStats,
        cache
      );
      if (callQuality != cache.callQuality) {
        onCallQuality.emit(callQuality);
        cache.callQuality = callQuality;
      }
      cache.previous = newStats;
      onRtcStats.emit(stats);
      onCallQualityStats.emit(callQualityStats);
    });
  };
  let it = window.setInterval(pushStats, interval);
  const clearInterval = () => {
    window.clearInterval(it);
    it = 0;
  };
  const resumeStats = () => {
    if (it === 0) {
      cache = newCache();
      pushStats();
      it = window.setInterval(pushStats, interval);
    }
  };
  return {
    resetStats: () => {
      clearInterval();
      return resumeStats;
    },
    cleanup: clearInterval
  };
};

// src/utils.ts
import { createSignal } from "@pexip/signal";
var SIGNAL_PREFIX = "call:peerConnection:stats";
var REQUIRED_STATS_SIGNAL_KEYS = [
  "onRtcStats",
  "onCallQualityStats",
  "onCallQuality"
];
var createStatsSignals = (scope = "") => {
  const signalScope = scope && [scope, ":"].join("");
  return REQUIRED_STATS_SIGNAL_KEYS.reduce(
    (signals, key) => ({
      ...signals,
      [key]: createSignal({
        name: `${SIGNAL_PREFIX}:${signalScope}:${key}`,
        allowEmittingWithoutObserver: true
      })
    }),
    {}
  );
};
export {
  Quality,
  STATS_SIZE,
  addDeltaStats,
  calculateQuality,
  createResolver,
  createStatsCollector,
  createStatsSignals,
  getQuality,
  inboundAudio,
  inboundVideo,
  outboundAudio,
  outboundVideo,
  statsFrom,
  statsFromRTCPeer
};
